<template>
	<b-row>
		<b-col md="12">
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
				{{ FormMSG(1, 'Reporting') }}
			</h1>
		</b-col>
		<b-col md="12">
			<b-card no-body :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
				<b-tabs card active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
					<b-tab v-if="isAlbert" :title="FormMSG(8, 'Albert')">
						<b-card no-body>
							<b-tabs card active-nav-item-class="font-weight-bold" active-tab-class="font-weight-bold">
								<b-tab lazy :title="FormMSG(4, 'Production')">
									<Albertprod />
								</b-tab>
								<b-tab lazy :title="FormMSG(5, 'Action Plan')">
									<Albertactionplan />
								</b-tab>
								<b-tab lazy :title="FormMSG(6, 'Action Report')">
									<Albertactionreport />
								</b-tab>
								<b-tab lazy :title="FormMSG(7, 'Carbon footprint')">
									<Albertcarbonfootprint />
								</b-tab>
							</b-tabs>
						</b-card>
					</b-tab>
					<b-tab v-if="isGreenFilm" :title="FormMSG(9, 'Green Film')">
						<b-tabs card>
							<b-tab :title="FormMSG(2, 'Prerequisite')">
								<Prerequisite />
							</b-tab>
							<b-tab lazy :title="FormMSG(3, 'Final Report')">
								<Finalreport />
							</b-tab>
						</b-tabs>
					</b-tab>
					<b-tab v-if="isCarbonClap" :title="FormMSG(10, 'Carbon clap')">
						<Reporting />
					</b-tab>
					<b-tab v-if="isPeachPearPlum" :title="FormMSG(11, 'PEACH PEAR PLUM')">
						<PeachPearPlumDashboard />
					</b-tab>
				</b-tabs>
			</b-card>
		</b-col>
	</b-row>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import Albertactionplan from '../albert/albertactionplan.vue';
import Albertactionreport from '../albert/albertactionreport.vue';
import Albertcarbonfootprint from '../albert/albertcarbonfootprint.vue';
import Albertprod from '../albert/albertprod.vue';
import Finalreport from '../greenfilm/finalreport.vue';
import PeachPearPlumDashboard from '../../modules/peach-pear-plum/views/dashboard.vue';
import Prerequisite from '../greenfilm/prerequisite.vue';
import { store } from '@/store';
import Reporting from '../../modules/carbonclap/views/reporting/index.vue';

export default {
	name: 'Green',
	mixins: [languageMessages],
	components: { Prerequisite, Finalreport, Albertprod, Albertactionplan, Albertactionreport, Albertcarbonfootprint, Reporting, PeachPearPlumDashboard },
	computed: {
		isGreenFilm() {
			return store.greenReporting() === 1;
		},
		isAlbert() {
			return store.greenReporting() === 2;
		},
		isCarbonClap() {
			return store.isCarbonClapReporting();
		},
		isPeachPearPlum() {
			return store.isPeachPearPlum();
		}
	}
};
</script>
<style lang=""></style>
