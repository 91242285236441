<style>
.albert-border-bottom {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row>
			<!-- <b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ FormMSG(10, 'Action Plan') }}
				</h1>
			</b-col> -->
			<b-col cols="12">
				<p>{{ this.FormMSG(1, 'Action Plan') }}</p>
				<p>
					{{
						this.FormMSG(
							2,
							`The carbon action plan is designed for those productions who not only want to measure their carbon footprint but actively take steps towards reducing it. Although this is an optional step for most productions, some broadcasters require you to complete an action plan so make sure to check if you're not sure. It's free to use and productions who successfully complete it are awarded a digital certification logo that can be added to your show's endboard.`
						)
					}}
				</p>
				<p>
					{{
						this.FormMSG(
							3,
							`If you're using the albert calculator and already looking at how to reduce your footprint then you should definitely consider completing a carbon action plan so that your hard work is recognised. Albert awards productions 1,2 or 3 stars depending on both the number and type of actions they take.`
						)
					}}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12">
				<b-card v-if="menus.length > 0">
					<b-row v-for="(menu, i) in menus" :key="i" :class="`${i === menus.length - 1 ? 'py-3' : 'albert-border-bottom py-3'}`">
						<b-col cols="12" md="8" class="py-2">
							<strong class="text-secondary">
								{{ menu.text }}
							</strong>
						</b-col>
						<b-col cols="12" md="4">
							<b-button variant="light" block @click="handleActionPlan(menu.value)">{{ FormMSG(41, 'View / Edit') }}</b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'AlbertActionPlan',
	mixins: [languageMessages],

	computed: {
		menus() {
			const menus = this.FormMenu(1305);

			return menus.length === 1 && menus[0].value === 0 && menus[0].text === 'not found' ? [] : menus;
		}
	},

	data() {
		return {
			hv: 'dark',
			erreur: {},
			warning: '',
			isLoading: false
		};
	},

	methods: {
		handleActionPlan(id) {
			this.$router.push(`/albertactionplan/${id}`);
		}
	}
};
</script>
