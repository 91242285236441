var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "h1",
          {
            class: [
              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
              ,
              { "is-pwa": _vm.$isPwa() },
            ],
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.FormMSG(1, "Reporting")) + "\n\t\t")]
        ),
      ]),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "b-card",
            {
              class: `${_vm.$screen.width <= 576 ? "my-project" : ""}`,
              attrs: { "no-body": "" },
            },
            [
              _c(
                "b-tabs",
                {
                  attrs: {
                    card: "",
                    "active-nav-item-class": "font-weight-bold",
                    "active-tab-class": "font-weight-bold",
                  },
                },
                [
                  _vm.isAlbert
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(8, "Albert") } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-tabs",
                                {
                                  attrs: {
                                    card: "",
                                    "active-nav-item-class": "font-weight-bold",
                                    "active-tab-class": "font-weight-bold",
                                  },
                                },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        lazy: "",
                                        title: _vm.FormMSG(4, "Production"),
                                      },
                                    },
                                    [_c("Albertprod")],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        lazy: "",
                                        title: _vm.FormMSG(5, "Action Plan"),
                                      },
                                    },
                                    [_c("Albertactionplan")],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        lazy: "",
                                        title: _vm.FormMSG(6, "Action Report"),
                                      },
                                    },
                                    [_c("Albertactionreport")],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        lazy: "",
                                        title: _vm.FormMSG(
                                          7,
                                          "Carbon footprint"
                                        ),
                                      },
                                    },
                                    [_c("Albertcarbonfootprint")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isGreenFilm
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(9, "Green Film") } },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { card: "" } },
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: _vm.FormMSG(2, "Prerequisite"),
                                  },
                                },
                                [_c("Prerequisite")],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    lazy: "",
                                    title: _vm.FormMSG(3, "Final Report"),
                                  },
                                },
                                [_c("Finalreport")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCarbonClap
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.FormMSG(10, "Carbon clap") } },
                        [_c("Reporting")],
                        1
                      )
                    : _vm._e(),
                  _vm.isPeachPearPlum
                    ? _c(
                        "b-tab",
                        {
                          attrs: { title: _vm.FormMSG(11, "PEACH PEAR PLUM") },
                        },
                        [_c("PeachPearPlumDashboard")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }